import React, { useState } from 'react';

import DropdownOption, { DropdownOptionType } from './DropdownOption/DropdownOption';

import Input from 'components/Shared/Inputs/Input';
import { colors } from 'styles/cp';

export interface DropdownProps {
  options?: DropdownOptionType[];
  disabled?: boolean;
  placeholder?: string;
  onSelectOption: (option: DropdownOptionType) => void;
}

const Dropdown = (props: DropdownProps) => {
  const { options, onSelectOption, disabled, placeholder = '' } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOptionType>({
    name: '',
    issuerId: '',
  });

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectOption = (option: DropdownOptionType) => {
    setSelectedOption(option);
    toggleDropdown();
    onSelectOption(option);
  };

  return (
    <div className="dropdown-container">
      <Input
        data-testid="dropdown-input"
        onClick={toggleDropdown}
        readOnly
        disabled={disabled}
        value={selectedOption.name || placeholder}
      />
      {isOpen && (
        <ul className="dropdown-options-list">
          {options?.map((option: DropdownOptionType) => (
            <DropdownOption
              key={option.issuerId}
              option={option}
              onClick={() => handleSelectOption(option)}
            />
          ))}
        </ul>
      )}
      <style jsx>
        {`
          .dropdown-container {
            position: relative;
          }
          .dropdown-options-list {
            display: ${isOpen && options ? 'block' : 'none'};
            z-index: ${isOpen && options ? '2' : '1'};
            border-radius: 4px;
            box-shadow: 0px 1px 4px 1px ${colors.gray05};
            background-color: white;
            padding: 4px 0px;
            font-size: 16px;
            list-style: none;
            margin-top: 4px;
            border: 1px solid ${colors.green};
          }
        `}
      </style>
    </div>
  );
};
export default Dropdown;
