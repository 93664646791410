import React from 'react';
import { FormattedMessage } from 'react-intl';

import ScrollingForm, {
  InjectedScrollingFormProps,
} from 'components/Core/Payment/PaymentForm/ScrollingForm/ScrollingForm';
import Dropdown from 'components/Shared/Dropdown/Dropdown';
import { DropdownOptionType } from 'components/Shared/Dropdown/DropdownOption/DropdownOption';
import { fontSize } from 'styles/cp';

type PayByBankFormProps = {} & InjectedScrollingFormProps;

const options: DropdownOptionType[] = [
  {
    name: 'Option 1',
    issuerId: 'option1',
  },
  {
    name: 'Option 2',
    issuerId: 'option2',
  },
  {
    name: 'Option 3',
    issuerId: 'option3',
  },
];

const PayByBankForm: React.FC<PayByBankFormProps> = () => {
  const handleSelectedOption = (option: DropdownOptionType) => {
    return option?.issuerId;
  };

  return (
    <React.Fragment>
      <div className="pbb-form">
        <div className="pbb-form-text">
          <div className="pbb-form-header">
            <FormattedMessage id="PBB_HEADER" defaultMessage="Select a bank account" />
          </div>
          <div className="pbb-form-body">
            <FormattedMessage
              id="PBB_BODY"
              defaultMessage="Bank payments are simple, safe, and secure with QuickBooks."
            />
          </div>
        </div>
        <div className="pbb-dropdown">
          <Dropdown
            placeholder="Choose your bank"
            onSelectOption={handleSelectedOption}
            options={options}
          />
        </div>
      </div>
      <style jsx>{`
        .pbb-form-text {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;

          .pbb-form-header {
            font-size: ${fontSize.md2};
            font-weight: 600;
            line-height: 28px;
          }

          .pbb-form-body {
            font-size: ${fontSize.sm};
            font-weight: 400;
            line-height: 24px;
          }
        }
        .pbb-dropdown {
          padding-top: 12px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default ScrollingForm(PayByBankForm);
