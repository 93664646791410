import React from 'react';

import { colors } from 'styles/cp';

export interface DropdownOptionType {
  name: string;
  issuerId: string;
  logoUrl?: string;
}
export interface DropdownOptionProps {
  option: DropdownOptionType;
  onClick?: () => void;
}

const DropdownOption = (props: DropdownOptionProps) => {
  const { option, onClick } = props;

  return (
    <div>
      <li className="dropdown-option" onClick={onClick}>
        {option?.logoUrl && <img className="dropdown-option image" src={option?.logoUrl} />}
        <span className="dropdown-option name">{option?.name}</span>
      </li>
      <style jsx>
        {`
          .dropdown-option {
            color: ${colors.gray01};
            cursor: pointer;
            padding: 5px 8px;
            z-index: 999;
            :hover {
              background-color: #2ca01c;
              color: white;
            }
          }
        `}
      </style>
    </div>
  );
};

export default DropdownOption;
